.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #0112e5;
  justify-content: space-between;
  text-align: center;
  color: #fff;
  height: 94px;
  padding-right: 30px;
  padding-left: 30px;
}

.App-link {
  color: #61dafb;
}

.Modal-title {
  font-size: 24px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn {
  outline: 0;
  border: 0;
  box-shadow: none;
  display: inline-block;
  font-weight: 900;
  line-height: 1.5;
}

.btn-launch-app {
  width: auto;
  height: 40px;
  background-color: #4bf0d3;
  color: #0540f2;
  border-radius: 5px;
  font-family: CooperHewitt-bold;
  text-transform: uppercase;
  padding: 8px 15px;
  /* position: absolute; */
  /* right: 75px; */
}

.btn-bell {
  background-color: rgba(0, 0, 0, 0.5);
  color: #4bf0d3;
  border-color: #4bf0d3;
  border-radius: 5px;
  width: 38px !important;
  height: 40px;
}

.badge {
  margin-right: 14px;
  border: 0px;
}

.margin-right-8 {
  margin-right: 8px;
}

@media (min-width: 992px) {
  .menu {
    display: none;
  }
}

body {
  margin: 0;
  font-family: CooperHewitt-book,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div,
span,
input,
table,
td,
tr,
h1,
h2,
h3,
h4 {
  font-family: CooperHewitt-book,sans-serif;
}
